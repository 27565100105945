import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import SEO from '../components/common/SEO'
import Page from '../components/common/Page.layout'
import { BGBlocks, CTALink } from '../components/UI'

const Wrap = styled.div`
	.page-content {
		width: 100%;
		min-height: 80vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(
			to right,
			${props => props.theme.colors.lightCtaBg.val} 0%,
			${props => props.theme.colors.lightCtaBg.tint(0).val} 100%
		);
		& > .bg {
			max-width: 50%;
			svg {
				opacity: 0.4;
			}
		}
		.links {
			padding: 1em 2em;
			background: ${props => props.theme.colors.white.tint(90).val};
			${props => props.theme.media.sdesk} {
				padding: 4em 6em;
			}
			li {
				margin: 0.5em 0;
			}
		}
	}
`

const HomePage = ({ data }) => {
	const { links } = data?.data

	return (
		<Wrap>
			<SEO pageData={{ pageTitle: 'ACI Learning' }} />
			<Page pageData={{ pageTitle: 'ACI Learning' }}>
				<div className='page-content'>
					<BGBlocks />
					{links && (
						<ul className='links'>
							{links.map(({ title, link, id }) => (
								<li key={id}>
									<CTALink isBlankTab to={link}>
										{title}
									</CTALink>
								</li>
							))}
						</ul>
					)}
				</div>
			</Page>
		</Wrap>
	)
}

export const pageQuery = graphql`
	query homePageQuery {
		data: contentfulGlobalInfo {
			links: homeLinks {
				id
				link
				title
			}
		}
	}
`

HomePage.propTypes = {}
export default HomePage
